import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'
// import { Document, Page } from 'react-pdf'
import { useConnect } from 'redux-bundler-hook'
import Chip from '@mui/material/Chip'
import Pagination from '@mui/material/Pagination'
import Checkbox from './common/Checkbox'
import { CircularProgress } from '@mui/material'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import GppBadIcon from '@mui/icons-material/GppBad'
import { getLanguageLocaleDateOptions, numericLocaleDateOptions } from '../utils/dates'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"items preview"',
    ].join('\n'),
    gridTemplateColumns: '2fr 6fr',
    minHeight: '70vh',
    gap: 10,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  docLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  documentContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 800,
    maxHeight: 600,
  },
  pageStyle: {
    border: '1px solid #ebebeb',
    borderRadius: 20,
    boxShadow: '0px 0px 8px 0px #ebebeb',
  },
  items: {
    gridArea: 'items',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY: 'auto',
    maxHeight: 600,
  },
  itemPagination: {
    gridArea: 'pagination',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
  },
  preview: {
    gridArea: 'preview',
    overflowY: 'auto',
    // border: '1px solid #ebebeb',
    // borderRadius: 10,
    // boxShadow: '0px 0px 8px 0px #ebebeb',
  },
  elementDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #c4c4c4',
    borderRadius: 10,
    padding: 10,
    '&:hover': {
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
    },
    gap: 5,
  },
  selectedElementDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '2px solid #2196f3',
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    gap: 5,
  },
}))

const TraceDocumentComponent = props => {
  const {
    traceDocument,
    classes,
    onClick,
    selectedDoc,
    dateDisplayPrefValue,
    isEcgAssigned = false,
  } = props
  const ts = new Date(traceDocument.createdOn)
  const fullYear = ts.getFullYear()
  const date = ts.toLocaleDateString(
    undefined,
    dateDisplayPrefValue ? getLanguageLocaleDateOptions(fullYear) : numericLocaleDateOptions,
  )
  const time = ts.toLocaleTimeString()
  return (
    <div
      className={
        // TODO: refactor this wtf was i thinking
        isEcgAssigned && !selectedDoc
          ? classes.selectedElementDiv
          : selectedDoc?.id === traceDocument?.id
            ? classes.selectedElementDiv
            : classes.elementDiv
      }
      onClick={() => onClick(traceDocument)}
    >
      {isEcgAssigned && (
        <Typography fontSize={14} fontWeight='bold' sx={{ color: '#1769aa' }}>ASIGNADO</Typography>
      )}
      <Tooltip title={<Typography fontFamily='monospace' fontSize={11}>{traceDocument?.name}</Typography>} arrow placement='bottom'>
        <Typography
          fontFamily='monospace'
          fontSize={12}
          noWrap
          sx={{
            padding: '5px',
            border: '1px solid #9CA7B2',
            borderRadius: 5,
            width: '100%',
          }}>
          {/* {stringToMaxLength(traceDocument.name, 45)} */}
          {traceDocument?.name}
        </Typography>
      </Tooltip>
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <Chip size='small' label={`ID: ${traceDocument?.id}`} variant='outlined' color='secondary' style={{ fontFamily: 'roboto' }} />
        <Typography fontFamily='roboto' fontSize={14}>Centro: {traceDocument?.center?.name ?? 'N/A'}</Typography>
      </div>
      <Typography fontFamily='roboto' fontSize={14}>{date} - {time}</Typography>
      <div style={{ display: 'flex', gap: 5, justifyContent: 'space-between' }}>
        <div style={{ minWidth: '80%', display: 'flex', gap: 5 }}>
          <Chip
            label={traceDocument?.ecgPtr ? `ECG ID: ${traceDocument?.ecgPtr}` : 'Sin asignar'}
            color={traceDocument?.ecgPtr ? 'info' : 'warning'}
            variant='outlined'
            size='small'
            sx={{
              width: 'auto',
              fontFamily: 'roboto',
              fontSize: 14
            }}
          />
          {traceDocument?.metadata?.deviceId && (
            <Chip
              label={traceDocument?.metadata?.deviceId}
              color='info'
              size='small'
              variant='outlined'
              sx={{
                fontFamily: 'roboto',
                width: 'auto',
                fontSize: 14
              }}
            />
          )}
        </div>
        <div>
          {traceDocument?.ocrRunning && (
            <Tooltip title='Extrayendo datos'>
              <CircularProgress color='info' size={25} />
            </Tooltip>
          )}
          {!traceDocument?.ocrRunning && (
            <Tooltip title={traceDocument?.ocrLatestData ? 'Datos extraídos' : 'Datos no extraídos'}>
              {traceDocument?.ocrLatestData ? <VerifiedUserIcon color='info' /> : <GppBadIcon color='warning' />}
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

const AssignTraceTable = props => {
  // TODO: add border in different color if a trace.id is on relatedTraces to show which traces are related already
  // add also a flow to RELATE THEM and another flow to ASSIGN one of them. Maybe control this with a button on the dialog
  // Add button to clear the related traces completely, as well as the assigned trace.
  // Add message to after clicking on a trace which is already related to show the related traces. doSetSnackbarMessage
  // Add a button to remove some related traces (removeFromRelatedTraces endpoint)
  // Add a button to clear the related traces (special case using removeFromRelatedTraces endpoint with all traces)
  const {
    assignTracesTableRaw,
    assignTracesTableSelectedItem,
    assignTracesFilterParams,
    doSetAssignTracesTableSelectedItem,
    doSetAssignTracesTablePage,
    doSetAssignTracesFilterParams,
    userPreferences,
    selectedEcg = null,
  } = props
  const classes = useStyles()
  const dateDisplayPref = userPreferences.find(userPref => userPref.preference.key === 'DATE_DISPLAY_LONG') ?? { value: false }

  if (assignTracesTableRaw.loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        {!!selectedEcg && selectedEcg?.assignedTrace && (
          <TraceDocumentComponent
            key='selectedEcgDocument'
            traceDocument={selectedEcg?.assignedTrace}
            classes={classes}
            onClick={doc => doSetAssignTracesTableSelectedItem(doc)}
            selectedDoc={assignTracesTableSelectedItem}
            dateDisplayPrefValue={dateDisplayPref.value}
            isEcgAssigned
          />
        )}
        <div className={classes.itemPagination}>
          <Pagination
            count={assignTracesTableRaw.totalPages}
            variant='outlined'
            shape='rounded'
            color='primary'
            onChange={(event, value) => doSetAssignTracesTablePage(value)}
          />
          <Tooltip placement='top' arrow title='Incluye los trazos asignados.'>
            <Checkbox
              checked={assignTracesFilterParams?.all}
              label='Todos'
              onChange={e => {
                const checked = e.target.checked
                if (checked) {
                  doSetAssignTracesFilterParams({ all: true })
                } else {
                  doSetAssignTracesFilterParams({})
                }
              }}
            />
          </Tooltip>
        </div>
        {assignTracesTableRaw.items.map(item => (
          <TraceDocumentComponent
            key={item.id}
            traceDocument={item}
            classes={classes}
            onClick={doc => doSetAssignTracesTableSelectedItem(doc)}
            selectedDoc={assignTracesTableSelectedItem}
            dateDisplayPrefValue={dateDisplayPref.value}
          />
        ))}
      </div>
      {/* <div className={classes.preview}>
        <Document
          className={classes.documentContainer}
          file={assignTracesTableSelectedItem?.filePtr}
          loading={(
            <div className={classes.docLoading}>
              <CircularProgress />
            </div>
          )}
        >
          <Page
            className={classes.pageStyle}
            pageNumber={1}
            height={700}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div> */}
      <div className={classes.preview}>
        {assignTracesTableSelectedItem?.filePtr ? (
          <iframe
            src={assignTracesTableSelectedItem.filePtr}
            type='application/pdf'
            width='100%'
            height='100%'
          />
        ) : (
          <div className={classes.docLoading}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectAssignTracesTableRaw',
    'selectUserPreferences',
    'selectAssignTracesFilterParams',
    'selectAssignTracesTableSelectedItem',
    'doSetAssignTracesTableSelectedItem',
    'doSetAssignTracesTablePage',
    'doSetAssignTracesFilterParams',
  )

  return <AssignTraceTable {...props} {...connectedProps} />
}
