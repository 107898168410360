import { Button, TextField as MUITextField, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Grow from '@mui/material/Grow'
import { makeStyles } from '@mui/styles'
import { useDebounce } from '@uidotdev/usehooks'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import Checkbox from '../components/common/Checkbox'
import Select from '../components/common/Select'
import Switch from '../components/common/Switch'
import TextField from '../components/common/TextField'
import { daysInMonth, months, strtDateTimeToDate, years } from '../utils/dates'
import { chewDataForAPICreate } from '../utils/ecg'
import { AILMENT_NUM_OF_COLUMNS, checkReasonOptions, orderedAilments } from '../utils/formVars'
import { getSexValue } from '../utils/misc'
import { toTitleCase } from '../utils/string'
import { getConsentFormValidation } from '../utils/validationSchemas'
import { ModalPolitics } from './common/ModalPoliticts'

const initialFormData = {
  names: '',
  lastNames: '',
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  lastECGDay: null,
  lastECGMonth: null,
  lastECGYear: null,
  sex: null,
  hidden: false,
  tas: null,
  tad: null,
  urgency: false,
  height: null,
  smoker: false,
  alcoholic: false,
  diabetic: false,
  hypertensive: false,
  previousSurgery: false,
  transfusions: false,
  hepatitis: false,
  heartDiseaseHistory: false,
  cholesterol: false,
  weight: null,
  previousEcg: false,
  pacemaker: false,
  patient: null,
  pacemakerKind: '',
  resultNormal: true,
  checkReason: 'REVISIÓN GENERAL',
  thirdAuthorization: false,
  thirdName: null,
  relationship: null,
  withInterpretation: true,
  notes: '',
  isResponsible: '',
  studioId: null,
  isNew: false,
  email: '',
  thirdEmail: '',
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  fullName: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  verticalGap: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  checkRegisteredPatientDiv: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxWidth: '40rem',
  },
  verticalGapLarge: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  birthDate: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  thirdData: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    marginLeft: 20,
  },
  previousEcg: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
  previousEcgData: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 10,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  fullWidth: {
    width: '100%',
  },
  newOrExisting: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    gap: 10,
    justifyContent: 'space-between',
  },
  createButton: {
    minWidth: 200,
    minHeight: 200,
  },
}))

const ConsentForm = props => {
  const {
    patientsProxy,
    selectorEntities,
    patientsLoading,
    userId,
    kind,
    centers,
    userCenterId,
    userCenterOrganizationId,
    userCenterOrganization,
    doGetPatient,
    doSearchPatients,
    doAddECG,
    doCloseDrawer,
  } = props

  const classes = useStyles()
  const patients = selectorEntities.map((p) => {
    const firstLetter = p.name[0].toUpperCase()
    return {
      ...p,
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      value: p.id,
      label: p.name,
    }
  })

  const [patient, setPatient] = React.useState()
  const [isNew, setIsNew] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedPatientSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    const fetchSearchPatients = async () => {
      await doSearchPatients(searchTerm)
    }
    fetchSearchPatients()
  }, [debouncedPatientSearchTerm])

  const [openPolitics, setOpenPolitics] = useState(false)
  const formik = useFormik({
    validationSchema: getConsentFormValidation(isNew),
    initialValues: {
      ...initialFormData,
      centerInfo: userCenterId,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: () => setOpenPolitics(true),
  })

  const onSubmit = async () => {
    const reduxVals = {
      organization: userCenterOrganizationId,
      userCenterId,
      centerInfo: formik.values.centerInfo,
      userId
    }
    const newVals = chewDataForAPICreate(formik.values, reduxVals)
    if (globalThis.localStorage.getItem('debug')) console.log(newVals)
    const res = await doAddECG(newVals)
    if (res) {
      formik.resetForm()
      doCloseDrawer()
    }
    setOpenPolitics(false)
  }

  const ailmentCheckboxes = useMemo(() =>
    AILMENT_NUM_OF_COLUMNS.map(multiplier => {
      const slice = orderedAilments.slice(0 + 3 * multiplier, 3 + 3 * multiplier)
      return (
        <div key={`ailment-col-${multiplier + 1}`} className={classes.column}>
          {slice.map(ailment => (
            <Checkbox
              key={ailment.name}
              name={ailment.name}
              checked={formik.values[ailment.name]}
              label={ailment.displayName}
              labelPlacement='end'
              color='secondary'
              onChange={formik.handleChange}
            />
          ))}
        </div>
      )
    }), [formik.values]
  )

  return (
    <div>
      <ModalPolitics
        open={openPolitics}
        handleSubmit={onSubmit}
        handleClose={() => setOpenPolitics(false)}
      />
      <form onSubmit={formik.handleSubmit}>
        <div name='patient-data' className={classes.root}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.4rem',
              color: '#486581',
            }}
          >
            DATOS DEL PACIENTE
          </Typography>
          {kind === 0 && (
            <Select
              size='small'
              placeholder='Centro <developer>'
              name='centerInfo'
              options={centers.map(c => ({ key: c.name, displayValue: c.name, value: c.id }))}
              value={formik.values.centerInfo}
              onValueChange={formik.handleChange}
              width={300}
            />
          )}
          <div className={classes.checkRegisteredPatientDiv}>
            <Alert severity='warning'>
              Antes de admitir a un nuevo paciente, por favor
              verifique con la barra de búsqueda si se ha realizado algún estudio previo en éste centro
              o en algun centro de la organización ({userCenterOrganization?.name}).
              En caso afirmativo, les solicitamos amablemente seleccionar al paciente existente.
              <strong> Esto para permitir diagnosticar con un expediente completo.</strong>
            </Alert>
          </div>
          <div name='new-or-existing' className={classes.newOrExisting}>
            <Select
              size='small'
              placeholder='Nuevo/Existente'
              name='isNew'
              options={[
                { key: 'NUEVO PACIENTE', displayValue: 'Nuevo Paciente', value: true },
                { key: 'PACIENTE EXISTENTE', displayValue: 'Paciente Existente', value: false },
              ]}
              value={formik.values.isNew}
              onValueChange={e => {
                formik.handleChange(e)
                setIsNew(e.target.value)
              }}
              width={300}
            />
            {kind === 0 && (<div className={classes.row}>
              <Typography>Hidden</Typography>
              <Switch
                name='hidden'
                checked={formik.values.hidden}
                onChange={formik.handleChange}
              />
            </div>)}
          </div>
          {formik.values.isNew ? (
            <div name='fullname' className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                }}
              >
                Nombre completo
              </Typography>
              <div className={classes.fullName}>
                <TextField
                  margin='dense'
                  disabled={!formik.values.isNew}
                  placeholder='Nombre (s)'
                  name='names'
                  rounded
                  dense
                  fullWidth
                  value={formik.values.names}
                  onValueChange={formik.handleChange}
                />
                <TextField
                  margin='dense'
                  disabled={!formik.values.isNew}
                  placeholder='Apellidos (s)'
                  name='lastNames'
                  dense
                  rounded
                  fullWidth
                  value={formik.values.lastNames}
                  onValueChange={formik.handleChange}
                />
              </div>
            </div>
          ) : (
            <div className={classes.fullWidth}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                  marginBottom: 10,
                }}
              >
                Seleccionar paciente
              </Typography>
              <Autocomplete
                fullWidth
                name='patient'
                value={patient}
                options={patients}
                onChange={async (event, newValue) => {
                  let selected
                  if (patientsProxy.hasOwnProperty(newValue)) patientsProxy[newValue]
                  else selected = await doGetPatient(newValue)
                  setPatient(selected)
                  const birthDate = strtDateTimeToDate(selected.birthDate)
                  await formik.setValues({
                    ...formik.values,
                    patient: selected.id,
                    birthDay: birthDate.getUTCDate(),
                    birthMonth: birthDate.getUTCMonth() + 1,
                    birthYear: birthDate.getUTCFullYear(),
                    sex: getSexValue(selected.sex),
                    email: selected.email,
                  })
                }}
                inputValue={searchTerm}
                onInputChange={(event, newInputValue) => {
                  setSearchTerm(newInputValue)
                }}
                groupBy={option => option.firstLetter}
                renderInput={(params) => (
                  <MUITextField {...params} label='Existente' placeholder='Paciente existente' />
                )}
                loading={patientsLoading}
              />
            </div>
          )}
          <div name='birthDate' className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Fecha de Nacimiento
            </Typography>
            <div className={classes.birthDate}>
              <Select
                displayEmpty
                disabled={!formik.values.isNew}
                placeholder='Día'
                name='birthDay'
                options={() => {
                  const totalDays = daysInMonth(formik.values.birthMonth, formik.values.birthYear) || 31
                  const daysArr = Array.from(Array(totalDays).keys())
                  return daysArr.map(n => (
                    { key: `day-${n + 1}`, displayValue: n + 1, value: n + 1 }
                  ))
                }}
                dense
                onValueChange={formik.handleChange}
                value={formik.values.birthDay}
                width='110px'
              />
              <Select
                displayEmpty
                placeholder='Mes'
                disabled={!formik.values.isNew}
                dense
                name='birthMonth'
                width='160px'
                options={() => {
                  return months.map(month => (
                    { key: month.name, displayValue: month.name, value: month.value }
                  ))
                }}
                onValueChange={formik.handleChange}
                value={formik.values.birthMonth}
              />
              <Select
                displayEmpty
                placeholder='Año'
                disabled={!formik.values.isNew}
                dense
                name='birthYear'
                options={() => {
                  return years.map(year => (
                    { key: `year-${year}`, displayValue: year, value: year }
                  ))
                }}
                onValueChange={formik.handleChange}
                value={formik.values.birthYear}
                width='160px'
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <div className={classes.verticalGap}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#707070',
                  }}
                >
                  Sexo
                </Typography>
                <Select
                  displayEmpty={false}
                  dense
                  disabled={!formik.values.isNew}
                  value={formik.values.sex}
                  name='sex'
                  options={[
                    { key: 'FEMENINO', displayValue: 'Femenino', value: 'FEMENINO' },
                    { key: 'MASCULINO', displayValue: 'Masculino', value: 'MASCULINO' },
                  ]}
                  onValueChange={formik.handleChange}
                  width='150px'
                />
              </div>
            </div>
            <div className={classes.fullWidth}>
              <div className={classes.verticalGap}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#707070',
                  }}
                >
                  Presión Arterial
                </Typography>
                <div className={classes.row}>
                  <TextField
                    type='number'
                    margin='dense'
                    name='tas'
                    adornmentPosition='end'
                    endAdornmentText='TAS'
                    dense
                    rounded
                    fullWidth
                    value={formik.values.tas}
                    onValueChange={formik.handleChange}
                  />
                  <Typography sx={{ fontSize: '2rem' }}>
                    {'/'}
                  </Typography>
                  <TextField
                    type='number'
                    margin='dense'
                    adornmentPosition='end'
                    endAdornmentText='TAD'
                    name='tad'
                    dense
                    rounded
                    fullWidth
                    value={formik.values.tad}
                    onValueChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <div className={classes.verticalGap}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#707070',
                  }}
                >
                  Estatura
                </Typography>
                <div>
                  <TextField
                    margin='dense'
                    type='number'
                    adornmentPosition='end'
                    endAdornmentText='cm'
                    name='height'
                    rounded
                    dense
                    fullWidth
                    value={formik.values.height}
                    onValueChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={classes.fullWidth}>
              <div className={classes.verticalGap}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#707070',
                  }}
                >
                  Peso
                </Typography>
                <div>
                  <TextField
                    margin='dense'
                    adornmentPosition='end'
                    endAdornmentText='kg'
                    type='number'
                    name='weight'
                    rounded
                    dense
                    fullWidth
                    value={formik.values.weight}
                    onValueChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.fullWidth}>
              <div className={classes.verticalGap}>
                <div className={classes.checkRegisteredPatientDiv}>
                  <Alert severity='info'>
                    De ser posible, y para ofrecer una respuesta al estudio de manera mas ágil,
                    ingrese o confirme el correo del paciente para
                    enviarle el resultado en cuanto esté listo.
                  </Alert>
                </div>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#707070',
                  }}
                >
                  Email del paciente
                </Typography>
                <div>
                  <TextField
                    margin='dense'
                    type='email'
                    placeholder='Email'
                    name='email'
                    // disabled={!formik.values.isNew && patient?.email}
                    rounded
                    dense
                    fullWidth
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.touched.email && Boolean(formik.errors.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.touched.email && formik.errors.email && formik.errors.email}
                    helperTextStyles={{color: 'red', fontWeight: 800}}
                    onValueChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div name='patientHistory' className={classes.verticalGapLarge}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.4rem',
              color: '#486581',
            }}
          >
            HISTORIAL
          </Typography>
          <div className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Padecimientos
            </Typography>
            <div className={classes.row}>
              {ailmentCheckboxes}
            </div>
          </div>
          <div className={classes.verticalGap}>
            <div className={classes.row}>
              <Switch
                name='pacemaker'
                checked={formik.values.pacemaker}
                onChange={formik.handleChange}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                }}
              >
                Marcapaso
              </Typography>
            </div>
            <div className={classes.fullWidth}>
              <div className={classes.row}>
                {formik.values.pacemaker && (
                  <div style={{ width: '100%' }}>
                    <TextField
                      margin='dense'
                      placeholder='Tipo de marcapaso'
                      name='pacemakerKind'
                      rounded
                      dense
                      fullWidth
                      value={formik.values.pacemakerKind}
                      onValueChange={formik.handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.previousEcg}>
            <div className={classes.row}>
              <Switch
                name='previousEcg'
                checked={formik.values.previousEcg}
                onChange={async event => {
                  if (!event.target.checked) {
                    await formik.setValues({
                      ...formik.values,
                      lastECGDay: 1,
                      lastECGMonth: 1,
                      lastECGYear: 2022,
                      previousEcg: false,
                      resultNormal: true,
                    })
                  }
                  formik.handleChange(event)
                }}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                }}
              >
                ECG Previo
              </Typography>
            </div>
            {formik.values.previousEcg && (
              <div className={classes.column} style={{ marginTop: 20 }}>
                <div className={classes.previousEcgData}>
                  <Select
                    placeholder='Día'
                    name='lastECGDay'
                    options={() => {
                      const totalDays = daysInMonth(formik.values.birthMonth, formik.values.birthYear) || 31
                      const daysArr = Array.from(Array(totalDays).keys())
                      return daysArr.map(n => (
                        { key: `day-${n + 1}`, displayValue: n + 1, value: n + 1 }
                      ))
                    }}
                    dense
                    onValueChange={formik.handleChange}
                    value={formik.values.lastECGDay}
                    width='70px'
                  />
                  <Select
                    placeholder='Mes'
                    dense
                    name='lastECGMonth'
                    options={() => {
                      return months.map(month => (
                        { key: month.name, displayValue: month.name, value: month.value }
                      ))
                    }}
                    onValueChange={formik.handleChange}
                    value={formik.values.lastECGMonth}
                  />
                  <Select
                    placeholder='Año'
                    dense
                    name='lastECGYear'
                    options={() => {
                      return years.map(year => (
                        { key: `year-${year}`, displayValue: year, value: year }
                      ))
                    }}
                    onValueChange={formik.handleChange}
                    value={formik.values.lastECGYear}
                    width='80px'
                  />
                  <Select
                    placeholder='Resultado'
                    dense
                    name='resultNormal'
                    options={[
                      { displayValue: 'Normal', value: true, key: 'NORMAL' },
                      { displayValue: 'Anormal', value: false, key: 'ANORMAL' },
                    ]}
                    onValueChange={formik.handleChange}
                    value={formik.values.resultNormal}
                    width='150px'
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Typography fontSize={14}>
                    Último estudio
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
                marginBottom: 10,
              }}
            >
              Motivo
            </Typography>
            <Select
              size='small'
              dense
              placeholder='Motivo'
              name='checkReason'
              options={checkReasonOptions.map(opt => (
                { key: opt, displayValue: toTitleCase(opt), value: opt }
              ))}
              value={formik.values.checkReason}
              onValueChange={formik.handleChange}
              width='300px'
            />
          </div>
        </div>
        <div name='specs' className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.4rem',
              color: '#486581',
            }}
          >
            ESPECIFICACIONES DEL ESTUDIO
          </Typography>
          <div className={classes.verticalGap}>
            <div className={classes.row}>
              <Switch
                name='thirdAuthorization'
                checked={formik.values.thirdAuthorization}
                onChange={async event => {
                  if (!event.target.checked) {
                    await formik.setValues({
                      ...formik.values,
                      thirdName: '',
                      relationship: '',
                      thirdAuthorization: false,
                    })
                  }
                  formik.handleChange(event)
                }}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                }}
              >
                Autorización de terceros
              </Typography>
            </div>
            <div className={classes.fullWidth}>
              <div className={classes.column}>
                {formik.values.thirdAuthorization && (
                  <Grow in={formik.values.thirdAuthorization}>
                    <div className={classes.column}>
                      <TextField
                        margin='dense'
                        placeholder='Nombre'
                        name='thirdName'
                        rounded
                        dense
                        fullWidth
                        value={formik.values.thirdName}
                        onValueChange={formik.handleChange}
                      />
                      <TextField
                        margin='dense'
                        placeholder='Parentesco'
                        name='relationship'
                        rounded
                        dense
                        fullWidth
                        value={formik.values.relationship}
                        onValueChange={formik.handleChange}
                      />
                      <div className={classes.checkRegisteredPatientDiv}>
                        <Alert severity='info'>
                          De ser posible, y para ofrecer una respuesta al estudio de manera mas ágil,
                          ingrese el correo del del tercero
                          para enviarle el resultado en cuanto esté listo.
                        </Alert>
                      </div>
                      <div className={classes.fullWidth}>
                        <TextField
                          margin='dense'
                          placeholder='Email'
                          type='email'
                          name='thirdEmail'
                          rounded
                          dense
                          fullWidth
                          value={formik.values.thirdEmail}
                          onBlur={formik.handleBlur}
                          error={formik.touched.thirdEmail
                            && formik.touched.thirdEmail
                            && Boolean(formik.errors.thirdEmail && formik.errors.thirdEmail)
                          }
                          helperText={formik.touched.thirdEmail && formik.touched.thirdEmail && formik.errors.thirdEmail && formik.errors.thirdEmail}
                          helperTextStyles={{color: 'red', fontWeight: 800}}
                          onValueChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Grow>
                )}
              </div>
            </div>
          </div>
          <div className={classes.verticalGap}>
            <div className={classes.row}>
              <Switch
                name='urgency'
                checked={formik.values.urgency}
                onChange={formik.handleChange}
              />
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#707070',
                }}
              >
                Urgente
              </Typography>
            </div>
          </div>
          <div className={classes.row}>
            <Switch
              name='withInterpretation'
              checked={formik.values.withInterpretation}
              onChange={formik.handleChange}
            />
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Interpretación
            </Typography>
          </div>
          <div className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Observaciones
            </Typography>
            <TextField
              placeholder='Observaciones'
              name='observations'
              fullWidth
              value={formik.values.observations}
              onValueChange={formik.handleChange}
              multiline
            />
          </div>
          <div className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Encargado del estudio
            </Typography>
            <TextField
              placeholder='Nombre completo'
              name='isResponsible'
              fullWidth
              value={formik.values.isResponsible}
              onValueChange={formik.handleChange}
              multiline
            />
          </div>
          <div className={classes.verticalGap}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#707070',
              }}
            >
              Folio
            </Typography>
            <TextField
              placeholder='Folio'
              name='studioId'
              fullWidth
              value={formik.values.studioId}
              onValueChange={formik.handleChange}
              multiline
            />
          </div>
        </div>
        {
          Object.values(formik.errors).map((value, index) => (
            <Alert key={index} severity='warning'>
              {value}
            </Alert>
          ))
        }
        <Button
          type='submit'
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          variant='contained'
          sx={{
            marginTop: 2,
            minWidth: 150,
            minHeight: 30,
            backgroundColor: '#F94654',
          }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1rem',
              color: 'white',
            }}
          >
            Crear
          </Typography>
        </Button>
      </form>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectPatientsProxy',
    'doSearchPatients',
    'selectSelectorEntities',
    'selectPatientsLoading',
    'selectUserCenterId',
    'selectUserCenterOrganizationId',
    'selectUserCenterOrganization',
    'selectCenters',
    'selectUserId',
    'selectKind',
    'doGetPatient',
    'doAddECG',
    'doCloseDrawer',
  )
  return <ConsentForm {...props} {...connectedProps} />
}
