import React, { useMemo } from 'react'
import ViewSDKClient from './ViewSDKClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import actions from '../../utils/actions'
import { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'

const useStyles = makeStyles(() => ({
  adminNoDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      opacity: 0.8,
      color: 'black',
      cursor: 'pointer',
    }
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}))

const Viewer = props => {
  const {
    apiKey,
    ecgData,
    kind,
  } = props

  const classes = useStyles()
  let pdf = null

  if (ecgData?.version === 1) {
    pdf = ecgData?.assignedTrace?.filePtr ?? null
  } else if (ecgData?.version === 0) {
    pdf = ecgData?.ecg
  }

  const loadPDF = () => {
    if (!pdf) return
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') return
    const viewSDKClient = new ViewSDKClient(apiKey)
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        'pdf-div',
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false,
        },
        pdf,
        apiKey,
      )
    })
  }

  useEffect(() => {
    loadPDF()
  }, [])

  const returnVal = () => pdf ? (
    <div style={{ height: '100%', width: '100%' }}>
      {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
        <div
          id='pdf-div'
        />
      ): (
        <iframe src={pdf} type="application/pdf" width="100%" height="95%">
          <p>Link <a href={pdf}>al PDF.</a></p>
        </iframe>
      )}
    </div>
  ) : (
    <div
      className={kind <= 1 ? classes.adminNoDataDiv : classes.noDataDiv}
      onClick={async () => {
        if (kind > 1) return
        const inp = document.querySelector('#edit-dialog-file-inp')
        inp.click()
      }}
    >
      {ecgData?.version === 0 && (
        <form style={{ display: 'none'}}>
          <input id='edit-dialog-file-inp' type='file' onChange={e => actions.onFileUpload(e, ecgData.id)} />
        </form>
      )}
      {ecgData?.version === 1 && (
        <div id='edit-dialog-file-inp' onClick={() => actions.onClickAssignTrace(ecgData)}></div>
      )}
      <FontAwesomeIcon
        icon='fa-sharp fa-solid fa-file'
        size='3x'
        opacity={0.1}
      />
      <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
        Trazo no cargado.
        {kind <= 1 && ecgData?.version === 0 && ' Haga click para cargar trazo.'}
        {ecgData?.version === 1 && ' Haga click aqui para abrir el selector de trazos.'}
      </Typography>
    </div>
  )
  return useMemo(returnVal, [pdf])
}
export default props => {
  const connectedProps = useConnect('selectUserFlags')
  return <Viewer {...props} {...connectedProps} />
}
