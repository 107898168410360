import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import { makeStyles } from '@mui/styles'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { timeSince } from '../utils/dates'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  items: {
    gridArea: 'items',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  deviceDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #c4c4c4',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
    },
    gap: 5,
    width: '100%',
  },
  itemPagination: {
    gridArea: 'pagination',
    display: 'flex',
    justifyContent: 'start',
    gap: 1,
    width: '100%',
  },
}))

const NEVER_CONNECTED_TEXT = 'nunca conectado'

export const DeviceComponent = props => {
  const {
    device,
    classes,
    // doSetDeviceFilesSelectedDevice,
    // doSetDeviceFilesDialogOpen,
    doFetchDeviceFiles,
    doSetSnackbarFail,
  } = props
  const ts = device.lastOnline ? new Date(device.lastOnline) : null
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  let date = ''
  let timeSinceLastOnline = NEVER_CONNECTED_TEXT
  if (ts) {
    timeSinceLastOnline = timeSince(new Date(device.lastOnline))
    date = ts.toLocaleDateString()
    if (ts.toLocaleDateString() == today.toLocaleDateString()) date = 'hoy'
    else if (ts.toLocaleDateString() == yesterday.toLocaleDateString()) date = 'ayer'
  } else {
    timeSinceLastOnline = NEVER_CONNECTED_TEXT
  }

  const isOnline = device.isOnline
  const timeSinceLastTrace = device.lastPacketReceived
    ? new Date(device.lastPacketReceived).toLocaleString()
    : 'nunca'

  return (
    <div className={classes.deviceDiv}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: 'solid 1px #c4c4c4',
          width: '100%',
          padding: 10,
        }}
      >
        <div style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'center' }}>
          <Chip
            label={`SN: ${device.serialNumber}`}
            variant='contained'
            color='info'
            sx={{
              fontFamily: 'monospace',
              fontSize: 12
            }}
          />
          <Typography fontFamily='roboto' fontSize={14}>
            &quot;{device.name}&quot;
          </Typography>
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center', width: '100%', justifyContent: 'end' }}>
          <Typography fontFamily='roboto' fontStyle='italic' fontSize={11}>
            última conexión: {date} {timeSinceLastOnline}
          </Typography>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: isOnline ? '#00FF7F' : '#ff3333',
            }}
          />
          <Typography fontFamily='roboto' fontSize={12}>
            {isOnline ? 'Conectado' : 'Desconectado'}
            {/* circle div */}
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', padding: 10 }}>
        <div style={{ display: 'flex', minWidth: '50%', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* left */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div style={{ display: 'flex', gap: 5 }}>
              <Typography fontFamily='roboto' fontSize={14}>
                Tipo de dispositivo:
              </Typography>
              <Typography fontFamily='roboto' fontSize={14} fontStyle='italic'>
                {device.deviceType.name}
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: 5 }}>
              <Typography fontFamily='roboto' fontSize={14}>
                Último trazo recibido:
              </Typography>
              <Typography fontFamily='roboto' fontSize={14} fontStyle='italic'>
                {timeSinceLastTrace}
              </Typography>
            </div>
          </div>
          <Button
            onClick={async () => {
              // doSetDeviceFilesSelectedDevice(device)
              const success = await doFetchDeviceFiles(device)
              if (!success) {
                doSetSnackbarFail('No se pudo conectar al dispositivo. Verifique configuración o contacte a soporte')
                return
              }
              // doSetDeviceFilesDialogOpen(true)
            }}
            variant='outlined'
            color='info'
            sx={{
              width: 'fit-content',
            }}
          >
            Ver archivos
          </Button>
        </div>
        {/*  ------------  */}
        <div style={{ display: 'flex', minWidth: '50%', flexDirection: 'column', alignItems: 'end' }}>
          {/* right */}
          <img src={device.deviceType.image} style={{ maxHeight: 100 }}></img>
        </div>
      </div>

    </div>
  )
}

const DevicesTable = props => {
  const {
    devicesTableItems,
    devicesTableRaw,
    doSetDevicesTablePage,
    doSetDeviceFilesSelectedDevice,
    doSetDeviceFilesDialogOpen,
    doFetchDeviceFiles,
    doSetSnackbarFail,
  } = props
  const {
    loading
  } = devicesTableRaw

  if (!devicesTableItems.length && loading) return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  )
  if (!devicesTableItems.length) return (
    <div>
      <Typography variant='body'>
        No hay dispositivos
      </Typography>
    </div>
  )
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.itemPagination}>
        <Pagination
          count={devicesTableRaw.totalPages}
          variant='outlined'
          shape='rounded'
          color='primary'
          onChange={(event, value) => doSetDevicesTablePage(value)}
        />
      </div>
      <div className={classes.items}>
        {loading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: 150, alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : devicesTableItems.map(item => (
          <DeviceComponent
            key={item.id}
            device={item}
            classes={classes}
            doSetDeviceFilesSelectedDevice={doSetDeviceFilesSelectedDevice}
            doSetDeviceFilesDialogOpen={doSetDeviceFilesDialogOpen}
            doFetchDeviceFiles={doFetchDeviceFiles}
            doSetSnackbarFail={doSetSnackbarFail}
          />
        ))}
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectDevicesTableItems',
    'selectDevicesTableRaw',
    'doSetDevicesTablePage',
    'doSetDeviceFilesSelectedDevice',
    'doSetDeviceFilesDialogOpen',
    'doFetchDeviceFiles',
    'doSetSnackbarFail',
  )
  return (
    <DevicesTable {...props} {...connectedProps} />
  )
}
