import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import NotificationBanner from './common/NotificationBanner'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
    borderRadius: 5,
  },
  notificationList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    borderRadius: 15,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    minHeight: 150,
  },
}))

const NotificationsTray = props => {
  const classes = useStyles()
  const {
    notifications,
    anchorEl,
    onClose,
    isMobile,
    userPreferences,
  } = props
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const width = isMobile ? 300 : 450

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.root}
      PaperProps={{
        elevation: 3,
        sx: {
          borderRadius: 5,
          padding: 1,
          backgroundColor: '#EEEEEE',
          minWidth: 450,
        },
      }}
    >
      <div className={classes.notificationList}>
        <Typography
          fontSize='2rem'
          fontFamily='roboto'
          fontWeight={500}
        >
          Notificaciones
        </Typography>
        {notifications?.length ? notifications.map(n => (
          <NotificationBanner
            key={n.id}
            notification={n}
            width={width}
            userPreferences={userPreferences}
            {...props}
          />
        )) : (
          <div className={classes.noDataDiv}>
            <FontAwesomeIcon
              icon='fa-sharp fa-solid fa-clipboard-check'
              size='3x'
              opacity={0.1}
            />
            <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
              No hay notificaciones.
            </Typography>
          </div>
        )}
      </div>
    </Popover>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectNotifications',
    'selectIsMobile',
    'selectUserPreferences',
    'doSnoozeNotification',
    'doUnsnoozeNotification',
    'doUpdateUrl',
    'doOpenEcgDialogOnUrlUpdate',
  )
  return <NotificationsTray {...props} {...connectedProps} />
}
