import React from 'react'
import Drawer from '@mui/material/Drawer'
import ConsentForm from '../components/ConsentForm'
import Offline from '../components/common/Offline'

export const DrawerComponent = props => {
  const {
    doCloseDrawer,
    doOpenDrawer,
    isOnline,
    open,
  } = props

  const paperJustifyContent = !isOnline ? 'center' : 'flex-start'

  return (
    <Drawer
      anchor='right'
      BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.1 } }}
      PaperProps={{
        style:
        {
          // borderRadius: '20px 0 0 20px',
          padding: '30px',
          // minWidth: '40%',
          backgroundColor: 'white',
          justifyContent: paperJustifyContent,
        },
      }}
      elevation={5}
      open={open}
      onClose={doCloseDrawer}
      onOpen={doOpenDrawer}
    >
      {!isOnline ? (
        <Offline/>
      ) : (
        <ConsentForm />
      )
      }
    </Drawer>
  )
}

export default DrawerComponent
