import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
// eslint-disable-next-line no-unused-vars
import DialogActions from '@mui/material/DialogActions'
import RouterIcon from '@mui/icons-material/Router'
import { IconButton } from '@mui/material'
import { useConnect } from 'redux-bundler-hook'
import RefreshIcon from '@mui/icons-material/Refresh'
import Tooltip from '@mui/material/Tooltip'
import DevicesTable from '../DevicesTable'

const DeviceNavDialog = props => {
  const {
    deviceNavDialogOpen,
    doSetDeviceNavDialogOpen,
    doFetchDevicesTableList,
  } = props
  return (
    <Dialog
      open={deviceNavDialogOpen}
      onClose={() => doSetDeviceNavDialogOpen(false)}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, fontWeight: 'regular'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <RouterIcon />
          Dispositivos
        </div>
        <Tooltip title='Actualizar' placement='top' arrow>
          <IconButton onClick={doFetchDevicesTableList}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <DevicesTable />
      </DialogContent>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectDevicesTableItems',
    'selectDeviceNavDialogOpen',
    'doSetDeviceNavDialogOpen',
    'doFetchDevicesTableList',
  )
  return (
    <DeviceNavDialog {...props} {...connectedProps} />
  )
}
